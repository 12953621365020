import { openSuccessNotification } from '@/utils'

import { instance } from '../axios'

const getExampleUrl = (url: string) => `/stock/${url}`

export const StockService = {
	async getAll() {
		try {
			const { data } = await instance.get(getExampleUrl('all'))
			return data
		} catch (error) {
			console.log(error)
			return error
		}
	},
	async addImage(file: File, onProgress: (data: any) => void) {
		try {
			const body = new FormData()
			body.append('file', file)

			const { data } = await instance.post(getExampleUrl('create'), body, {
				onUploadProgress: (event) => {
					onProgress({ percent: (event.loaded / event.total!) * 100 })
				},
			})
			return data
		} catch (error) {
			console.log(error)
			return error
		}
	},
	async deleteById(id: string) {
		try {
			await instance.delete(getExampleUrl(id))
			return
		} catch (error) {
			console.log(error)
		}
	},
}
