import { ConfigProvider } from 'antd'
import type { AppProps } from 'next/app'
import { Provider } from 'react-redux'

import { TypeComponentAuthFields } from '@/types'

import { store } from '@/store'

import { theme } from '@/theme'

import MainProvider from '../app/providers/MainProvider'
import '../styles/globals.scss'

type TypeAppProps = AppProps & TypeComponentAuthFields

const MyApp = ({ Component, pageProps }: TypeAppProps) => {
	return (
		<Provider store={store}>
			<ConfigProvider theme={theme}>
				<MainProvider Component={Component}>
					<Component {...pageProps} />
				</MainProvider>
			</ConfigProvider>
		</Provider>
	)
}

export default MyApp
