import { openSuccessNotification } from '@/utils'

import { instance } from '../axios'

const getFeedbackUrl = (url: string) => `/feedback/${url}`

export const FeedbackService = {
	async newFeedback({
		name,
		phone,
		reset,
	}: {
		name: string
		phone: string
		reset: () => void
	}) {
		try {
			await instance.post(getFeedbackUrl('new'), {
				name,
				phone,
			})
			openSuccessNotification('Обратный звонок заказан')
			reset()
		} catch (error) {
			console.log(error)
			return error
		}
	},
	async newFeedbackWithProduct({
		name,
		phone,
		message,
		productName,
		typeConnection,
		reset,
	}: {
		name: string
		productName: string
		phone: string
		typeConnection: 'phone' | 'telegram' | 'whatsApp'
		message?: string
		reset: () => void
	}) {
		try {
			await instance.post(getFeedbackUrl('new-with-product'), {
				name,
				message,
				productName,
				phone,
				typeConnection,
			})
			openSuccessNotification('Обратный звонок заказан')
			reset()
		} catch (error) {
			console.log(error)
			return error
		}
	},
}
