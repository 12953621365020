import { IProduct } from 'types/Product.type'

import { instance } from '@/api'

import { openSuccessNotification } from '@/utils'

const getProductUrl = (url: string) => `/product/${url}`

export const ProductService = {
	async getAll(): Promise<IProduct[]> {
		try {
			const { data } = await instance.get(getProductUrl('all'))
			return data
		} catch (error) {
			console.log(error)
			return error
		}
	},

	async getBySlug({ slug }: { slug: string }) {
		try {
			const { data } = await instance.get(getProductUrl(slug))
			return data
		} catch (error) {
			console.log(error)
			return error
		}
	},
	async create({
		image,
		name,
		page,
		onSuccess,
	}: {
		name: string
		image: File
		page: string
		onSuccess: () => any
	}) {
		try {
			const body = new FormData()
			body.append('image', image)
			body.append('name', name)
			body.append('page', page)

			const { data } = await instance.post(getProductUrl('create'), body)
			openSuccessNotification('Продукт создан')
			onSuccess()
			return data
		} catch (error) {
			console.log(error)
		}
	},
	async updateById({
		id,
		image,
		name,
		page,
		onSuccess,
	}: {
		id: string
		name: string
		image: any
		page: string
		onSuccess: () => any
	}) {
		try {
			const isFile = (input) => 'File' in window && input instanceof File

			const body = new FormData()
			if (isFile(image)) body.append('image', image)
			body.append('name', name)
			body.append('page', page)

			const { data } = await instance.patch(getProductUrl(`update/${id}`), body)
			openSuccessNotification('Продукт создан')
			onSuccess()
			return data
		} catch (error) {
			console.log(error)
		}
	},
	async deleteById(id: string) {
		try {
			await instance.delete(getProductUrl(id))
			openSuccessNotification('Продукт удален')
		} catch (error) {
			console.log(error)
		}
	},
}
