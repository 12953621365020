import { IInfoUser } from '@/types'

import { instance } from '../axios'

const getUserUrl = (url: string) => `/user/${url}`

export const UserService = {
	async login(body: { email: string; password: string }): Promise<{
		accessToken: string
		refreshToken: string
	}> {
		try {
			const { data } = await instance.post(getUserUrl('login'), body)
			return data
		} catch (error) {
			console.log(error)
			return error
		}
	},
	async register(body: {
		fullName: string
		email: string
		password: string
		phone: string
	}) {
		try {
			const { data } = await instance.post(getUserUrl('register'), body)
			return data
		} catch (error) {
			console.log(error)
			return error
		}
	},
	async getInfo(): Promise<IInfoUser> {
		try {
			const { data } = await instance.get(getUserUrl('get-user-info'))
			return data
		} catch (error) {
			console.log(error)
			return error
		}
	},
}
