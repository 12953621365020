import Head from 'next/head'
import { FC, ReactNode } from 'react'

const HeadProvider: FC<{ children: ReactNode }> = ({ children }) => {
	return (
		<>
			<Head>
				<meta charSet="UTF-8" />

				<meta name="theme-color" content={'#181B1E'} />
				<meta name="msapplication-navbutton-color" content={'#181B1E'} />
				<meta
					name="apple-mobile-web-app-status-bar-style"
					content={'#181B1E'}
				/>
			</Head>
			{children}
		</>
	)
}

export default HeadProvider
