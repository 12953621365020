import { useRouter } from 'next/router'
import { FC, useEffect } from 'react'

import { TypeComponentAuthFields } from '@/types'

import { checkAuth, initializeApp, isShowMenu, useAppDispatch } from '@/store'

import AuthProvider from './AuthProvider/AuthProvider'
import HeadProvider from './HeadProvider/HeadProvider'
import YandexMetrika from './YandexMetrika'

const MainProvider: FC<TypeComponentAuthFields> = ({ children, Component }) => {
	const { pathname } = useRouter()

	const dispatch = useAppDispatch()

	useEffect(() => {
		dispatch(initializeApp())
		dispatch(checkAuth())
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		dispatch(isShowMenu(false))
		document.getElementById('body')?.classList.remove('_fixed')
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pathname])

	return (
		<>
			<YandexMetrika />
			<HeadProvider>
				<AuthProvider Component={Component}>{children}</AuthProvider>
			</HeadProvider>
		</>
	)
}

export default MainProvider
