import Cookies from 'js-cookie'
import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import { FC, useEffect } from 'react'

import { TypeComponentAuthFields } from '@/types'

import { checkAuth, logout, useAppDispatch, useAppSelector } from '@/store'

const DynamicCheckRole = dynamic(() => import('./CheckRole'), {
	ssr: false,
})

const AuthProvider: FC<TypeComponentAuthFields> = ({
	children,
	Component: { isOnlyUser },
}) => {
	const { token } = useAppSelector((state) => state?.auth)
	const dispatch = useAppDispatch()
	const { pathname } = useRouter()

	useEffect(() => {
		const accessToken = Cookies.get('token')
		if (accessToken) dispatch(checkAuth())
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		const refreshToken = Cookies.get('refreshToken')
		if (!refreshToken && token) dispatch(logout())
	}, [pathname]) // eslint-disable-line react-hooks/exhaustive-deps

	return !isOnlyUser ? (
		<>{children}</>
	) : (
		<DynamicCheckRole Component={{ isOnlyUser }}>{children}</DynamicCheckRole>
	)
}

export default AuthProvider
