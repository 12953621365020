import { loadingStatus } from '..'
import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import Cookies from 'js-cookie'

import { UserService } from '@/api'

export interface InitialStateType {
	isAuth: boolean
	email: string
	userId: string
	token: string
	error: string
	role: string
	inLogging: boolean
	inRegistration: boolean
}

const initialState: InitialStateType = {
	isAuth: false,
	email: '',
	userId: '',
	token: '',
	error: '',
	role: '',
	inLogging: false,
	inRegistration: false,
}

export const authReducer = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		setAuth: (state, action: PayloadAction<boolean>) => {
			state.isAuth = action.payload
		},
		setAuthData: (
			state,
			action: PayloadAction<{
				role: string
				email: string
				userId: string
				token: string
			}>
		) => {
			state.role = 'admin'
			state.email = action.payload.email
			state.userId = action.payload.userId
			state.token = action.payload.token
		},
	},
})

export const { setAuth, setAuthData } = authReducer.actions

export const login =
	({ email, password }: { email: string; password: string }) =>
	async (dispatch: any) => {
		dispatch(loadingStatus(true))

		try {
			const { accessToken, refreshToken } = await UserService.login({
				email,
				password,
			})
			accessToken && Cookies.set('token', accessToken)
			refreshToken && Cookies.set('refreshToken', refreshToken)
			const user = await UserService.getInfo()

			localStorage.setItem(
				'user',
				JSON.stringify({ ...user, token: accessToken })
			)

			dispatch(
				setAuthData({
					email: user.email,
					userId: user._id,
					role: 'admin',
					token: accessToken,
				})
			)
			dispatch(setAuth(true))
		} catch (err: any) {
			console.error(err)
		}

		dispatch(loadingStatus(false))
	}

export const logout = () => async (dispatch: any) => {
	dispatch(loadingStatus(true))

	try {
		localStorage.clear()
		Cookies.remove('token')
		Cookies.remove('refreshToken')
		dispatch(setAuth(false))
	} catch (err: any) {
		console.error(err)
	}

	dispatch(loadingStatus(false))
}

export const checkAuth = () => async (dispatch: any) => {
	dispatch(loadingStatus(true))

	try {
		const user = JSON.parse(localStorage.getItem('user')!)

		dispatch(setAuthData({ ...user, role: 'admin' }))
		dispatch(setAuth(true))
	} catch (err: any) {
		console.error(err)
	}

	dispatch(loadingStatus(false))
}

export const { reducer } = authReducer
