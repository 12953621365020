import { appReducer } from '.'
import { configureStore } from '@reduxjs/toolkit'

import { reducer } from './auth'

export const store = configureStore({
	reducer: {
		auth: reducer,
		app: appReducer.reducer,
	},
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: false,
		}),

	devTools: process.env.NODE_ENV !== 'production',
})

export type RootState = ReturnType<typeof store.getState>

export type AppDispatch = typeof store.dispatch
